import React from "react";
import styled from "styled-components";
import SimplifyIcon from "../../images/simplify-icon.svg";
import SaveTimeIcon from "../../images/saveTime-icon.svg";
import SaveMoneyIcon from "../../images/saveMoney-icon.svg";
import iphMockup from "../../images/defi-iph-mockup.png";
import iphMockupMobile from "../../images/defi-iph-mockup-mobile.png";
import { useTranslation } from "react-i18next";
import { breakPoints } from "../theme";

// const Wrapper = styled.div`
//   display: flex;
//   align-items: center;
//   flex-direction: column;
//   max-width: 1200px;
//   padding: 0 1em 5em;
//   margin: 0 auto 2rem;
//   h2 {
//     text-align: center;
//     font-size: 32px;
//     margin-bottom: 2em;
//   }
//   .widgetContainer {
//     display: flex;
//     width: 100%;
//     .missionWidget {
//       background: linear-gradient(231.19deg, #222222 0%, #182331 100%);
//       box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
//       border-radius: 6px;
//       color: white;
//       box-sizing: border-box;
//       margin: 0 2em;
//       padding: 3.5em 2.5em;
//       width: 30%;
//       img {
//         margin-bottom: 0.5rem;
//         height: 50px;
//       }

//       h3 {
//         font-size: 32px;
//         font-weight: 700;
//         margin-bottom: 1.5rem;
//       }
//       p {
//         margin: 0;
//         font-size: 20px;
//         font-family: Telex;
//       }
//     }
//     @media (max-width: 992px) {
//       flex-direction: column;
//       align-items: center;
//       .missionWidget {
//         width: auto;
//         max-width: 400px;
//         min-width: 250px;
//         margin: 0 0 2em;
//       }
//     }
//   }
// `;

const Wrapper = styled.div`
  background: #f8f7f3;
  display: flex;
  justify-content: center;

  .container {
    display: flex;
    width: 76.125em;
    margin-top: 128px;
    margin-bottom: 140px;
  }

  .left {
    margin-left: 5em;
    margin-right: 0em;
  }

  .widgetContainer {
    width: 30.75em;
  }

  .missionWidget {
    display: flex;
    background: #f8f7f3;
    align-items: flex-start;
    padding: 20px;
    margin: 20px;

    img {
      margin: 0 -10px;
      margin-top: -0.7em;
    }
  }

  .text {
    margin-left: 12px;
  }

  .missionWidget:hover {
    display: flex;
    background: #ffffff;
    align-items: flex-start;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 30.75em;
    padding: 20px;
    margin: 20px;

    h3 {
      color: #58afff;
    }
  }

  h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 2.5em;
    width: 15em;
    margin: 48px 0px;
    color: #3a4c61;
  }

  span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    color: #58afff;
  }

  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1.8125em;
    margin: 0;
    margin-bottom: 6.5px;
    color: #3a4c61;
  }

  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 1.25em;
    width: 20em;
    margin: 0;
    line-height: 24px;
    color: #3a4c61;
  }

  .mockup {
    max-width: 30em;
    margin: 1em 0;
  }

  .mobileVisible {
    display: none;
  }

  .mobileInvisible {
    display: block;
  }

  @media (max-width: ${breakPoints.sm}px) {
    .mobileVisible {
      display: block;
      width: 100%;
      margin: 2em 0;
    }

    .mobileInvisible {
      display: none;
    }
    .container {
      display: block;
      width: 22em;
      margin-top: 0;
      margin-bottom: 76px;
    }

    .left {
      width: 22em;
      margin: 0;
    }

    h2 {
      width: 335px;
      text-align: center;
      font-size: 1.875em;
      line-height: 37px;
    }

    .widgetContainer,
    .missionWidget,
    .missionWidget:hover {
      width: 21em;
      margin: 0 auto;

      img {
        margin: 0 -10px;
        margin-top: -15px;
      }
    }
    h3 {
      font-size: 1.5em;
      line-height: 29px;
      margin-bottom: 9.5px;
    }
    p {
      width: 15em;
      font-size: 1em;
      line-height: 19.5px;
    }

    .mockup {
      width: 100%;
      margin: 3em auto;
    }
  }
`;

const OurMission = () => {
  const { t } = useTranslation();
  const missionData = [
    {
      title: t("homepage.mission.simplify.title"),
      image: <img src={SimplifyIcon} alt="Simplify your finances"></img>,
      description: t("homepage.mission.simplify.description"),
    },
    {
      title: t("homepage.mission.saveTime.title"),
      image: <img src={SaveTimeIcon} alt="Save time with KeyFi"></img>,
      description: t("homepage.mission.saveTime.description"),
    },
    {
      title: t("homepage.mission.saveMoney.title"),
      image: <img src={SaveMoneyIcon} alt="Save Money with KeyFi"></img>,
      description: t("homepage.mission.saveMoney.description"),
    },
  ];
  return (
    <Wrapper>
      <div className="container">
        <div className="left">
          <h2>
            {t("homepage.mission.title")}{" "}
            <span>{t("homepage.mission.title2")}</span>
          </h2>
          <div className="widgetContainer">
            {missionData.map((item) => (
              <div key={item.title} className="missionWidget">
                {item.image}
                <div className="text">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <img
          className="mockup mobileInvisible"
          src={iphMockup}
          alt="iphone mockup"
        />
        <img
          className="mockup mobileVisible"
          src={iphMockupMobile}
          alt="iphone mockup"
        />
      </div>
    </Wrapper>
  );
};

export default OurMission;
