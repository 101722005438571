import React from "react";
import { lightTheme } from "../theme";
import AppCard from "./appCard";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import proPic from "../../images/pro-pic.png";
import proMenu from "../../images/pro-menu.png";
import proPicMobile from "../../images/pro-pic-mobile.png";
import proMenuMobile from "../../images/pro-menu-mobile.png";
import corePic from "../../images/core-pic.png";
import coreMenu from "../../images/core-menu.png";
import corePicMobile from "../../images/core-pic-mobile.png";
import coreMenuMobile from "../../images/core-menu-mobile.png";

const Wrapper = styled.div``;
const AppCardsWrap = styled.div``;

export default function AppCards() {
  const { t } = useTranslation();

  const standardVersion = {
    version: "core",
    bigImg: corePic,
    smImg: coreMenu,
    bigImgMobile: corePicMobile,
    smImgMobile: coreMenuMobile,
    title: t("homepage.getStart.keyfi.description"),
    listItems: [
      t("homepage.getStart.keyfi.function1"),
      t("homepage.getStart.keyfi.function2"),
      t("homepage.getStart.keyfi.function3"),
      t("homepage.getStart.keyfi.function4"),
    ],
  };

  const proVersion = {
    version: "pro",
    bigImg: proPic,
    smImg: proMenu,
    bigImgMobile: proPicMobile,
    smImgMobile: proMenuMobile,
    title: t("homepage.getStart.keyfiPro.description"),
    listItems: [
      t("homepage.getStart.keyfiPro.function2"),
      t("homepage.getStart.keyfiPro.function3"),
      t("homepage.getStart.keyfiPro.function4"),
      t("homepage.getStart.keyfiPro.function5"),
      t("homepage.getStart.keyfiPro.function6"),
    ],
  };
  return (
    <Wrapper>
      <AppCardsWrap>
        <AppCard pro={proVersion} core={standardVersion} />
      </AppCardsWrap>
    </Wrapper>
  );
}
