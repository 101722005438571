import * as React from "react";
import styled, { ThemeProvider } from "styled-components";
import "./index.css";

import { lightTheme } from "../components/theme";

//Components
import MetaComponent from "../components/MetaComponent";
import Hero from "../components/homePage/hero";
import Menu from "../components/Menu";
import AppCards from "../components/homePage/appCards";
import Integration from "../components/homePage/integration";
import Staking from "../components/homePage/staking";
import Footer from "../components/Footer";
import Wallets from "../components/homePage/wallets";
import OurMission from "../components/homePage/OurMission";
import FAQ from "../components/faq";
import TokenInfo from "../components/homePage/TokenInfo";
import Partner from "../components/homePage/Partner";

import { graphql } from "gatsby";

const Wrapper = styled.div`
  overflow: hidden;
  min-height: 100vh;
`;

const IndexPage = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <MetaComponent pageName="Home Page" />
      <Wrapper>
        <Menu activeMenu="home" />
        <Hero />
        <Integration />
        {/* <LaptopShowcase /> */}
        <OurMission />
        <Wallets />
        <AppCards />
        {/* <MediaIcons /> */}
        <Staking />
        <FAQ id="faq" />
        <TokenInfo />
        <Partner />
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
