import React from "react";

import Selfkey from "../../images/partner/SelfKey.svg";
import ApeSwap from "../../images/partner/Apeswap.svg";
import MEXC from "../../images/partner/MEXC.svg";
import Polygon from "../../images/partner/Polygon.svg";
import Aurora from "../../images/partner/aurora.svg";
import Alkemi from "../../images/partner/Alkemi.svg";
import Quickswap from "../../images/partner/Quickswap.svg";
import OKC from "../../images/partner/OKC.svg";

import "./Partner.css";
import styled from "styled-components";

const PartnerContainer = styled.div`
  max-width: 870px;
  margin: 3rem auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 8.75rem;
  row-gap: 2rem;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 6px;
    }
    p {
      font-size: 20px;
      color: #9caec1;
    }
  }
`;

const Partner = () => {
  return (
    <div className="wrapper">
      <p className="title">Partners & Investors</p>
      <PartnerContainer>
        <div>
          <img src={Selfkey} alt="Selfkey Strategic Partner" />
          <p>Strategic Partner</p>
        </div>
        <div>
          <img src={Polygon} alt="Selfkey Strategic Partner" />
          <p>Strategic Partner</p>
        </div>
        <div>
          <img src={MEXC} alt="Selfkey Strategic Partner" />
          <p>Strategic Partner</p>
        </div>
        <div>
          <img src={Aurora} alt="Aurora Strategic Partner" />
          <p>Strategic Partner</p>
        </div>
        <div>
          <img src={ApeSwap} alt="Selfkey Integration Partner" />
          <p>Integration Partner</p>
        </div>
        <div>
          <img src={Alkemi} alt="Alkemi Integration Partner" />
          <p>Integration Partner</p>
        </div>
        <div>
          <img src={Quickswap} alt="QuickSwap Integration Partner" />
          <p>Integration Partner</p>
        </div>
        <div>
          <img src={OKC} alt="OKC Integration Partner" />
          <p>Integration Partner</p>
        </div>
      </PartnerContainer>
    </div>
  );
};

export default Partner;
