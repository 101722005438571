import React from "react";
import Metamask from "../../images/icons/metamask2x.png";
import Selfkey from "../../images/icons/selfkey.svg";
import WalletConnect from "../../images/icons/walletconnect.svg";
import { useTranslation } from "react-i18next";

import "./wallets.css";

export default function Wallets() {
  const { t } = useTranslation();
  return (
    <div className="walletsWrap">
      <h2 className="walletWrapTitle">{t("homepage.connectWallet")}</h2>
      <div className="wallets">
        <img className="metamask" src={Metamask} alt="Metamask" />
        <img src={Selfkey} alt="Selfkey" />
        <img src={WalletConnect} alt="WalletConnect" />
      </div>
    </div>
  );
}
