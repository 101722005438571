import React, { useEffect } from "react";
import styled from "styled-components";
import StakingIcon from "./../../images/staking-icon.svg";
import GovernanceIcon from "./../../images/vote-icon.svg";
import { useTranslation } from "react-i18next";
import swapImg from "./../../images/exclusiveTools.png";
import swapImgMobile from "./../../images/exclusiveTools-mobile.png";
import { breakPoints } from "../theme";
// import "./staking.css";

const Wrapper = styled.div`
  background: #f8f7f3;
  display: flex;
  justify-content: center;
  padding: 3em 0;

  .container {
    display: flex;
    width: 77.125em;
  }

  .left {
    margin-top: 5em;
  }

  .widgetContainer {
    width: 30.75em;
    height: 25em;
    margin-right: 62px;
  }

  .toolTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    margin-bottom: 28px;
    color: #3b474e;
  }

  .tools {
    display: flex;
    background: #f8f7f3;
    align-items: flex-start;
    padding: 20px;
    margin: 40px 0px;
  }

  .tools:hover {
    display: flex;
    background: #ffffff;
    align-items: flex-start;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 20px;
    margin: 40px 0px;

    h3 {
      color: #58afff;
    }
  }

  .icon {
    margin: -4px -10px;
  }

  .text {
    margin-left: 10px;
  }

  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 29px;
    line-height: 35px;
    color: #3a4c61;
    margin: 6.5px 0;
  }

  p {
    width: 400px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    margin-top: 0;
    line-height: 24px;
    color: #3a4c61;
  }

  .mockup {
    width: 41em;
    margin-top: 3em;
  }

  .mobileVisible {
    display: none;
  }

  .mobileInvisible {
    display: block;
  }

  @media (max-width: ${breakPoints.sm}px) {
    padding-top: 3em;
    padding-bottom: 0;

    .mobileVisible {
      display: block;
      width: 21em;
      margin: 2em auto;
    }

    .mobileInvisible {
      display: none;
    }
    .container {
      display: block;
    }

    .left {
      margin: 0;
    }

    .icon {
      margin-top: -0.5em;
    }

    .toolTitle {
      font-size: 1.875em;
      line-height: 37px;
      text-align: center;
    }

    .widgetContainer {
      width: 22em;
      margin: 0 auto;
    }

    h3 {
      font-size: 1.5em;
      line-height: 29px;
      margin-bottom: 9.5px;
    }
    p {
      width: 16em;
      font-size: 1em;
      line-height: 19.5px;
    }
  }
`;

export default function Staking() {
  const { t } = useTranslation();

  const appVersion = [
    {
      icon: StakingIcon,
      title: t("homepage.exclusiveTools.staking.title"),
      paragraph: t("homepage.exclusiveTools.staking.description"),
    },
    {
      icon: GovernanceIcon,
      title: t("homepage.exclusiveTools.governance.title"),
      paragraph: t("homepage.exclusiveTools.governance.description"),
    },
  ];

  return (
    <Wrapper>
      <div id="vote" className="container ">
        <div className="left">
          <h2 className="toolTitle">{t("homepage.exclusiveTools.title")}</h2>
          <div className="widgetContainer">
            {appVersion.map((version, indx) => {
              return (
                <div key={indx} className="tools">
                  <img className="icon" src={version.icon} alt={version.icon} />
                  <div className="text">
                    <h3>{version.title}</h3>
                    <p>{version.paragraph}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <img className="mockup mobileInvisible" src={swapImg} alt="swap pic" />
        <img
          className="mockup mobileVisible"
          src={swapImgMobile}
          alt="swap pic"
        />
      </div>
    </Wrapper>
  );
}
