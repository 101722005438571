import React, { useState, useEffect } from "react";
import "./integration.css";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";

//icon
import Iinch from "../../images/1inch-color-1.svg";
import AAVE from "../../images/AAVE-color-2.svg";
import Alkemi from "../../images/Alkemi-color-3.svg";
import Apeswap from "../../images/Apeswap-color-4.svg";
import Bakeryswap from "../../images/Bakeryswap-bw-5.svg";
import BELT from "../../images/Belt Finance (BELT)-bw-6.svg";
import Binance from "../../images/Binance-color-7.svg";
import BNT from "../../images/BNT-bw-8.svg";
import Compound from "../../images/Compound-color-9.svg";
import CREAM from "../../images/CREAM-bw-10.svg";
import Curve from "../../images/Curve-color-11.svg";
import dYdXt from "../../images/dYdXt-color-12.svg";
import Ellipsis from "../../images/Ellipsis-bw-13.svg";
import Maker from "../../images/Maker-color-14.svg";
import Matic from "../../images/Matic-bw-15.svg";
import Nexo from "../../images/Nexo-bw-16.svg";
import Pancakeswap from "../../images/Pancakeswap-color-17.svg";
import Polygon from "../../images/Polygon-bw-18.svg";
import Quickswap from "../../images/Quickswap-bw-19.svg";
import Selfkey from "../../images/Selfkey-color-20.svg";
import SushiSwap from "../../images/SushiSwap-bw-21.svg";
import Synthetix from "../../images/Synthetix-bw-22.svg";
import Uniswap from "../../images/Uniswap-color-23.svg";
import WalletConnect from "../../images/WalletConnect-color-24.svg";

SwiperCore.use([Autoplay]);

export default function Integration() {
  SwiperCore.use([Autoplay]);

  const [bigScreen, setBigScreen] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 2200) {
      setBigScreen(true);
    } else {
      setBigScreen(false);
    }

    window.addEventListener("resize", function (event) {
      var newWidth = window.innerWidth;
      if (newWidth >= 2200) {
        setBigScreen(true);
      } else {
        setBigScreen(false);
      }
    });
  }, []);

  return (
    <>
      <div className="integrationWrap mobileHidden">
        <div className="integration">
          <Swiper
            autoplay={{
              delay: 2000,
            }}
            loop={true}
            spaceBetween={15}
            slidesPerView={bigScreen ? 11 : 9}
          >
            <SwiperSlide>
              <img src={Iinch} alt="Iinch logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={AAVE} alt="AAVE logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Alkemi} alt="Alkemi logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Apeswap} alt="Apeswap logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Bakeryswap} alt="Bakeryswap logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={BELT} alt="BELT logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Binance} alt="Binance logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={BNT} alt="BNT logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Compound} alt="Compound logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={CREAM} alt="CREAM logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Curve} alt="Curve logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={dYdXt} alt="dYdXt logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Ellipsis} alt="Ellipsis logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Maker} alt="Maker logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Nexo} alt="Nexo logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Pancakeswap} alt="Pancakeswap logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Polygon} alt="Polygon logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Quickswap} alt="Quickswap logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Selfkey} alt="Selfkey logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={SushiSwap} alt="SushiSwap logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Synthetix} alt="Synthetix logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Uniswap} alt="Uniswap logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={WalletConnect} alt="WalletConnect logo" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div className="integrationWrap mobileVisible">
        <div className="integration">
          <Swiper
            autoplay={{
              delay: 2000,
            }}
            loop={true}
            spaceBetween={10}
            slidesPerView={5}
          >
            <SwiperSlide>
              <img src={Iinch} alt="Iinch logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={AAVE} alt="AAVE logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Alkemi} alt="Alkemi logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Apeswap} alt="Apeswap logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Bakeryswap} alt="Bakeryswap logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={BELT} alt="BELT logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Binance} alt="Binance logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={BNT} alt="BNT logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Compound} alt="Compound logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={CREAM} alt="CREAM logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Curve} alt="Curve logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={dYdXt} alt="dYdXt logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Ellipsis} alt="Ellipsis logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Maker} alt="Maker logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Nexo} alt="Nexo logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Pancakeswap} alt="Pancakeswap logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Polygon} alt="Polygon logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Quickswap} alt="Quickswap logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Selfkey} alt="Selfkey logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={SushiSwap} alt="SushiSwap logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Synthetix} alt="Synthetix logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Uniswap} alt="Uniswap logo" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={WalletConnect} alt="WalletConnect logo" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}
