import React from "react";
import styled from "styled-components";
import Coinmarketcap from "../../images/icons/coinmarketcap.svg";
import CoinGecko from "../../images/icons/CoinGecko.svg";
import UniswapLogo from "../../images/icons/UniswapLogo.svg";
import PancakeSwap from "../../images/icons/PancakeSwap.svg";
import ApeSwap from "../../images/icons/ApeSwap.svg";
import LunarCrush from "../../images/icons/lunarcrush_logo.png";
import { useTranslation } from "react-i18next";
import Button from "../Button";
const Wrapper = styled.div`
  width: 100%;
  background: #f8f7f3;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    text-align: center;
    font-size: 32px;
  }
  .token_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    max-width: 750px;
    margin: 3rem auto 5rem;
    @media (max-width: 960px) {
      flex-direction: column;
      gap: 2rem;
    }
  }

  .button {
    background: #005ad3;
  }
`;

const TokenCard = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    transition: all 0.2s;
    max-height: 48px;
    max-width: 48px;
  }
  span {
    font-size: 24px;
    margin-left: 0.75rem;
    font-weight: 500;
  }
  :hover {
    img {
      transform: rotate(-45deg);
    }
    span {
      color: ${(props) => props.theme.brandBlue};
      text-decoration: underline;
    }
  }
`;

const tokens = [
  {
    title: "CoinMarketCap",
    image: Coinmarketcap,
    link: "https://coinmarketcap.com/currencies/keyfi/"
  },
  {
    title: "CoinGecko",
    image: CoinGecko,
    link: "https://www.coingecko.com/en/coins/keyfi"
  },
  {
    title: "Uniswap",
    image: UniswapLogo,
    link: "https://v2.info.uniswap.org/token/0xb8647e90c0645152fccf4d9abb6b59eb4aa99052"
  },
  {
    title: "PancakeSwap",
    image: PancakeSwap,
    link: "https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d"
  },
  {
    title: "ApeSwap",
    image: ApeSwap,
    link: "https://info.apeswap.finance/token/0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d"
  },
  {
    title: "LunarCrush",
    image: LunarCrush,
    link: "https://lunarcrush.com/coins/keyfi/keyfi-token?interval=1%20Week"
  }
];

const TokenInfo = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h2>{t("homepage.tokenInformation.title")}</h2>
      <div className="token_container">
        {tokens.map((token) => (
          <TokenCard href={token.link} target="_blank" rel="noopener noreferrer">
            <img src={token.image} alt={token.title}></img>
            <span>{token.title}</span>
          </TokenCard>
        ))}
      </div>

      <a href="https://app.keyfi.com/info" target="_blank" rel="noreferrer">
        <Button className="button" primary>
          More Info
        </Button>
      </a>
    </Wrapper>
  );
};

export default TokenInfo;
