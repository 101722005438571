import React, { useEffect } from "react";
import heroBackground from "../../images/background3x 1.png";
// mockup image
import BorrowDesktop from "../../images/_borrow-mockup-desktop 1.png";
import EarnMobile from "../../images/EarnPage-Core-mobile-withPhone 2.png";
import DashboardDesktop from "../../images/_borrow-mockup-desktop 2.png";
import SwapMobile from "../../images/_iphone-Swap 1.png";
import PRODashboardDesktop from "../../images/pro/_PRO-dashboard-mockup 2.png";
import StrategyManager from "../../images/pro/iphone PRO-strategy-manager.png";

import "./hero.css";
import styled, { css } from "styled-components";
import { breakPoints } from "../theme";

import "../Menu.js";
import { Link, Trans, useTranslation, i18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const StyledButton = styled.button`
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.font};
  padding: ${(props) => props.padding};
  border-radius: 5px;
  color: ${(props) => props.theme.white1};
  margin-bottom: ${(props) => props.marginbottom};
  margin-top: ${(props) => props.margintop};
  background: linear-gradient(90deg, #219653 0%, #3fb471 100%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  outline: 0;
  border: 0;
  cursor: pointer;
  text-transform: ${(props) => (props.uppercase ? "uppercase" : null)};
  transition: all 0.2s ease-in-out;
  @media (max-width: ${breakPoints.lg}px) {
    height: 48px;
  }
  ${(props) =>
    props.noMobile &&
    css`
      @media (max-width: ${breakPoints.lg}px) {
        display: none;
      }
    `}

  :hover {
    background: linear-gradient(90deg, #087738 0%, #19a555 100%);
  }
`;

export default function Hero() {
  const { t } = useTranslation();

  return (
    <div className="hero">
      <div className="hero-rap">
        <div className="heroText">
          <h1 id="mobileTitle" className="mobileVisible">
            {t("homepage.title")}
          </h1>
          <h1 className="mobileHidden">{t("homepage.title")}</h1>
          <h2 className="mobileVisible">{t("homepage.description")}</h2>
          <h2 className="mobileHidden">{t("homepage.description")}</h2>
          <a href="https://app.keyfi.com/">
            <StyledButton
              weight="600"
              font="1.25em"
              padding="0.75rem 1.5rem"
              margintop="1em"
              noMobile
            >
              {t("homepage.trybtn")}
            </StyledButton>
          </a>
        </div>

        <div className="imgWrapper fadein">
          <img className="borrow-desktop" src={BorrowDesktop} alt="Borrow desktop screenshot" />
          <img
            className="dashboard-desktop"
            src={DashboardDesktop}
            alt="Dashboard desktop screenshot"
          />
          <img
            className="proDashboard-desktop"
            src={PRODashboardDesktop}
            alt="pro dashboard desktop screenshot"
          />

          <img className="earn-mobile" src={EarnMobile} alt="Earn mobile screenshot" />
          <img className="swap-mobile" src={SwapMobile} alt="Swap mobile screenshot" />
          <img
            className="strategyManager-mobile"
            src={StrategyManager}
            alt="Strategy manager mobile screenshot "
          />
        </div>
      </div>
      <a href="https://app.keyfi.com/">
        <StyledButton
          weight="600"
          font="1.25em"
          padding="0.75rem   5rem"
          className="mobileVisible"
          margintop="20%"
          marginbottom="30%"
        >
          {t("homepage.trybtn")}
        </StyledButton>
      </a>
    </div>
  );
}
