import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import styled from "styled-components";
import { breakPoints } from "../theme";

const Wrapper = styled.div`
  background: #f8f7f3;
  display: flex;
  justify-content: center;

  .pro-container,
  .core-container {
    display: flex;
    justify-content: center;
    width: 82.125em;
  }

  .imgWrap {
    display: flex;
    margin: 5em 0;
    margin-right: 8.0625em;
    flex-direction: column;
  }

  .bigImg {
    width: 36em;
  }

  .smImg {
    margin-top: 2rem;
    width: 100%;
    max-width: 576px;
  }

  .textWrap {
    width: 31.25em;
    margin-left: 0em;
    margin-top: 10em;
  }
  .title {
    font-weight: 500;
    font-size: 1.5em;
    margin-bottom: 1.5rem;
    color: #3a4c61;
  }

  .sub-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 22px;
    color: #3a4c61;
    list-style-type: disc;
  }

  li {
    margin-left: 1em;
    margin-bottom: 1em;
  }

  .platformWrap {
    display: flex;
    align-items: center;
  }

  .platform {
    width: 86px;
    height: 33px;
    background: #005ad3;
    border-radius: 4.23322px;
    float: left;
  }

  .proTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1.3125em;
    line-height: 26px;
    letter-spacing: 0.5em;
    color: #ffffff;
    margin: 0px 8px;
    padding: 3px 0;
  }

  .unSelectplatform {
    width: 86.78px;
    height: 33px;
    background: #ced2d6;
    border-radius: 4.23322px;
    float: left;
  }
  .proUnselect {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1.3125em;
    line-height: 26px;
    letter-spacing: 0.5em;
    color: #dfdfdf;
    margin: 0px 8px;
    padding: 3px 0;
    cursor: pointer;
  }

  .slash {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 46px;
    color: #ced2d6;
    margin-top: 25px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .unslectCore {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 46px;
    color: #ced2d6;
    margin-top: 25px;
    margin-bottom: 24px;
    cursor: pointer;
    margin-left: 15px;
  }

  .coreTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 46px;
    color: #005ad3;
    margin-left: 15px;
    margin-top: 25px;
    margin-bottom: 24px;
  }

  .mobileVisible {
    display: none;
  }

  .mobileInvisible {
    display: flex;
  }

  @media (max-width: ${breakPoints.sm}px) {
    .imgWrap {
      margin-right: 0;
    }
    .mobileVisible {
      display: block;
    }

    .mobileInvisible {
      display: none;
    }

    .bigImg {
      width: 100%;
      margin-bottom: 1.25rem;
    }

    .smImg {
      width: 100%;
    }

    .pro-container,
    .core-container,
    .textWrap {
      width: 22em;
      margin-top: 1em;
    }

    li {
      font-size: 18px;
      margin-left: 2rem;
      margin-right: 0.5rem;
    }

    .title {
      font-size: 1.25em;
      text-align: center;
      margin-bottom: 24px;
    }

    .platformWrap {
      justify-content: center;
    }

    .title {
      width: auto;
    }

    .imgWrap {
      margin-bottom: 0;
      margin-top: 2em;
    }
  }
`;

export default function AppCard({ pro, core }) {
  const [showPro, setShowPro] = useState(true);

  const handlePro = () => {
    setShowPro(!showPro);
  };

  return (
    <Wrapper>
      {showPro ? (
        <>
          <div className="pro-container mobileInvisible">
            <div className="imgWrap">
              <img className="bigImg mobileInvisible" src={pro.bigImg} alt="pro app screenshot" />
              <img className="smImg mobileInvisible" src={pro.smImg} alt="pro app function" />
            </div>

            <div className="textWrap">
              <div className="platformWrap">
                <div className="platform">
                  <p className="proTitle">PRO</p>
                </div>
                <p className="unslectCore" onClick={handlePro}>
                  {" "}
                  <span className="slash">/</span> Core{" "}
                </p>
              </div>
              <p className="title">{pro.title}</p>
              <ul className="sub-text">
                {pro.listItems.map((item) => {
                  return <li>{item}</li>;
                })}
              </ul>
            </div>
          </div>

          <div className="pro-container mobileVisible">
            <div className="textWrap">
              <div className="platformWrap">
                <div className="platform">
                  <p className="proTitle">PRO</p>
                </div>
                <p className="unslectCore" onClick={handlePro}>
                  <span className="slash">/</span> Core{" "}
                </p>
              </div>
              <p className="title">{pro.title}</p>
              <ul className="sub-text">
                {pro.listItems.map((item) => {
                  return <li>{item}</li>;
                })}
              </ul>
            </div>
            <div className="imgWrap">
              <img className="bigImg" src={pro.bigImgMobile} alt="pro app screenshot" />
              <img className="smImg" src={pro.smImgMobile} alt="pro app function" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="core-container mobileInvisible">
            <div className="imgWrap">
              <img className="bigImg" src={core.bigImg} alt="core app screenshot" />
              <img className="smImg" src={core.smImg} alt="core app function" />
            </div>
            <div className="textWrap">
              <div className="platformWrap">
                <div className="unSelectplatform" onClick={handlePro}>
                  <p className="proUnselect">PRO</p>
                </div>
                <p className="coreTitle">
                  {" "}
                  <span className="slash">/</span> Core{" "}
                </p>
              </div>
              <p className="title">{core.title}</p>
              <ul className="sub-text">
                {core.listItems.map((item) => {
                  return <li>{item}</li>;
                })}
              </ul>
            </div>
          </div>
          <div className="core-container mobileVisible">
            <div className="textWrap">
              <div className="platformWrap">
                <div className="unSelectplatform" onClick={handlePro}>
                  <p className="proUnselect">PRO</p>
                </div>
                <p className="coreTitle">
                  {" "}
                  <span className="slash">/</span> Core{" "}
                </p>
              </div>
              <p className="title">{core.title}</p>
              <ul className="sub-text">
                {core.listItems.map((item) => {
                  return <li>{item}</li>;
                })}
              </ul>
            </div>
            <div className="imgWrap">
              <img className="bigImg" src={core.bigImgMobile} alt="core app screenshot" />
              <img className="smImg" src={core.smImgMobile} alt="core app function" />
            </div>
          </div>
        </>
      )}
    </Wrapper>
  );
}
